import React from "react";
import ImageContainer from "../Images";
import img1 from "../../images/kueche_01.jpg";
import img2 from "../../images/kueche_02.jpg";
import img3 from "../../images/kueche_03.jpg";
import img4 from "../../images/kueche_04.jpg";
import img5 from "../../images/kueche_05.jpg";
import img6 from "../../images/kueche_06.jpg";
import img7 from "../../images/kueche_07.jpg";
import ImageWrapper from "../ImageWrapper";
import PageWrapper from "../PageWrapper";

const Kueche = (children) => {
  return (
    <PageWrapper>
      <ImageWrapper>
        <ImageContainer src={img1} />
        <ImageContainer src={img2} />
        <ImageContainer src={img3} />
        <ImageContainer src={img4} />
        <ImageContainer src={img5} />
        <ImageContainer src={img6} />
        <ImageContainer src={img7} />
      </ImageWrapper>
    </PageWrapper>
  );
};

export default Kueche;
