import React from "react";
import ImageContainer from "../Images";
import img1 from "../../images/wohnzimmer_01.jpg";
import img2 from "../../images/wohnzimmer_02.jpg";
import img3 from "../../images/wohnzimmer_03.jpg";
import img4 from "../../images/wohnzimmer_04.jpg";
import img5 from "../../images/wohnzimmer_05.jpg";
import img6 from "../../images/wohnzimmer_06.jpg";
import img7 from "../../images/wohnzimmer_07.jpg";
import ImageWrapper from "../ImageWrapper";
import PageWrapper from "../PageWrapper";

const Wohnzimmer = (children) => {
  return (
    <PageWrapper>
      <ImageWrapper>
        <ImageContainer src={img1} />
        <ImageContainer src={img2} />
        <ImageContainer src={img3} />
        <ImageContainer src={img4} />
        <ImageContainer src={img5} />
        <ImageContainer src={img6} />
        <ImageContainer src={img7} />
      </ImageWrapper>
    </PageWrapper>
  );
};

export default Wohnzimmer;
