import React from "react";
import img1 from "../../images/logo.png";
import styled from "styled-components";

const Wrapper = styled.div`
  position: fixed;
  pointer-events: none;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Logo = styled.img`
  width: 35%;
`;

const Home = () => {
  return (
    <Wrapper>
      <Logo alt="" src={img1} />
    </Wrapper>
  );
};

export default Home;
