import React from "react";
import ImageContainer from "../Images";
import img1 from "../../images/bad_01.jpg";
import img2 from "../../images/bad_02.jpg";
import img3 from "../../images/bad_03.jpg";
import img4 from "../../images/bad_04.jpg";
import img5 from "../../images/bad_05.jpg";
import img6 from "../../images/bad_06.jpg";
import img7 from "../../images/bad_07.jpg";
import img8 from "../../images/bad_08.jpg";
import img9 from "../../images/bad_09.jpg";
import img10 from "../../images/bad_10.jpg";
import ImageWrapper from "../ImageWrapper";
import PageWrapper from "../PageWrapper";

const Bad = (children) => {
  return (
    <PageWrapper>
      <ImageWrapper>
        <ImageContainer src={img1} />
        <ImageContainer src={img2} />
        <ImageContainer src={img3} />
        <ImageContainer src={img4} />
        <ImageContainer src={img5} />
        <ImageContainer src={img6} />
        <ImageContainer src={img7} />
        <ImageContainer src={img8} />
        <ImageContainer src={img9} />
        <ImageContainer src={img10} />
      </ImageWrapper>
    </PageWrapper>
  );
};

export default Bad;