import React from "react";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Home from "./components/pages/Home";
import Arbeitszimmer from "./components/pages/Arbeitszimmer";
import Boot from "./components/pages/Boot";
import Bad from "./components/pages/Bad";
import Kueche from "./components/pages/Kueche";
import Regal from "./components/pages/Regal";
import Wohnzimmer from "./components/pages/Wohnzimmer";
import Sonstiges from "./components/pages/Sonstiges";
import Schlafzimmer from "./components/pages/Schlafzimmer";
import Tueren from "./components/pages/Tueren";
import Navbar from "./components/Navbar";
import styled from "styled-components";
import Contact from "./components/Contact";
import Kolberg from "./components/pages/Kolberg";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: 1023px) {
    flex-direction: column;
  }
`;

function App() {
  return (
    <Router>
      <Wrapper>
        <Navbar />
        <Switch>
          <Route path="/arbeitszimmer" component={Arbeitszimmer} />
          <Route path="/boot" component={Boot} />
          <Route path="/bad" component={Bad} />
          <Route path="/kueche" component={Kueche} />
          <Route path="/regal" component={Regal} />
          <Route path="/wohnzimmer" component={Wohnzimmer} />
          <Route path="/sonstiges" component={Sonstiges} />
          <Route path="/schlafzimmer" component={Schlafzimmer} />
          <Route path="/tueren" component={Tueren} />
          <Route path="/kolberg" component={Kolberg} />
          <Route exact path="/" component={Home} />
        </Switch>
        {/* <Contact /> */}
      </Wrapper>
    </Router>
  );
}

export default App;
