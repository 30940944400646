import React from "react";
import ImageContainer from "../Images";
import img1 from "../../images/kolberg_01.jpg";
import img2 from "../../images/kolberg_02.jpg";
import img3 from "../../images/kolberg_03.jpeg";
import img4 from "../../images/kolberg_04.jpeg";
import img5 from "../../images/kolberg_05.jpeg";
import img6 from "../../images/kolberg_06.jpeg";
import img7 from "../../images/kolberg_07.jpeg";
import img8 from "../../images/kolberg_08.jpeg";
import ImageWrapper from "../ImageWrapper";
import PageWrapper from "../PageWrapper";
import styled from "styled-components";

const MapWrapper = styled.div`
width: 65%;
height: 600px;
padding-top: 2rem;

@media (max-width: 1024px) {
  width: 85%;
}
`;

const Map = styled.iframe`
`;

const Kolberg = (children) => {
  return (
    <PageWrapper>
      
        <ImageWrapper>
        <ImageContainer src={img1} />
        <ImageContainer src={img2} />
        <ImageContainer src={img3} />
        <ImageContainer src={img4} />
        <ImageContainer src={img5} />
        <ImageContainer src={img6} />
        <ImageContainer src={img7} />
        <ImageContainer src={img8} />
      </ImageWrapper>
      <MapWrapper>
      <Map
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2443.2893696935785!2d13.806120515927178!3d52.23812746483231!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47a82282e382a653%3A0xfc3d505d372f55d3!2sBergstra%C3%9Fe%2025%2C%2015754%20Heidesee!5e0!3m2!1sde!2sde!4v1643663062539!5m2!1sde!2sde"
              width="100%"
              height="100%"
              frameBorder="0"
              style={{ border: 0 }}
              allowFullScreen=""
              aria-hidden="false"
              tabIndex="0"
            />
      
      </MapWrapper>
    </PageWrapper>
  );
};

export default Kolberg;