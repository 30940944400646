import React from "react";
import ImageContainer from "../Images";
import img1 from "../../images/arbeitszimmer_01.jpg";
import img2 from "../../images/arbeitszimmer_02.jpg";
import img3 from "../../images/arbeitszimmer_03.jpg";
import img4 from "../../images/arbeitszimmer_04.jpg";
import img5 from "../../images/arbeitszimmer_05.jpg";
import img6 from "../../images/arbeitszimmer_06.jpg";
import ImageWrapper from "../ImageWrapper";
import PageWrapper from "../PageWrapper";

const Arbeitszimmer = (children) => {
  return (
    <PageWrapper>
      <ImageWrapper>
        <ImageContainer src={img1} />
        <ImageContainer src={img2} />
        <ImageContainer src={img3} />
        <ImageContainer src={img4} />
        <ImageContainer src={img5} />
        <ImageContainer src={img6} />
      </ImageWrapper>
    </PageWrapper>
  );
};

export default Arbeitszimmer;