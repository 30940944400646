import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 1rem;
  background: yellow;
`;

const Contact = () => {
  return (
    <Wrapper>
      <p>
        E-Mail an <a href="mailto:ravan@berlin.de">ravan(at)berlin.de</a>
      </p>
    </Wrapper>
  );
};

export default Contact;
