import React from "react";
import ImageContainer from "../Images";
import img1 from "../../images/regal_01.jpg";
import img2 from "../../images/regal_02.jpg";
import img3 from "../../images/regal_03.jpg";
import img4 from "../../images/regal_04.jpg";
import img5 from "../../images/regal_05.jpg";
import img6 from "../../images/regal_06.jpg";
import img7 from "../../images/regal_07.jpg";
import img8 from "../../images/regal_08.jpg";
import img9 from "../../images/regal_09.jpg";
import img10 from "../../images/regal_10.jpg";
import img11 from "../../images/regal_11.jpg";
import img12 from "../../images/regal_12.jpg";
import img13 from "../../images/regal_13.jpg";
import img14 from "../../images/regal_14.jpg";
import img15 from "../../images/regal_15.jpg";
import ImageWrapper from "../ImageWrapper";
import PageWrapper from "../PageWrapper";

const Regal = (children) => {
  return (
    <PageWrapper>
      <ImageWrapper>
        <ImageContainer src={img1} />
        <ImageContainer src={img2} />
        <ImageContainer src={img3} />
        <ImageContainer src={img4} />
        <ImageContainer src={img5} />
        <ImageContainer src={img6} />
        <ImageContainer src={img7} />
        <ImageContainer src={img8} />
        <ImageContainer src={img9} />
        <ImageContainer src={img10} />
        <ImageContainer src={img11} />
        <ImageContainer src={img12} />
        <ImageContainer src={img13} />
        <ImageContainer src={img14} />
        <ImageContainer src={img15} />
      </ImageWrapper>
    </PageWrapper>
  );
};

export default Regal;
