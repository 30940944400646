import React from "react";
import styled from "styled-components";

const ImageContainer = styled.img`
  width: 100%;
  padding-top: 2rem;
`;

const Images = (props) => {
  return <ImageContainer alt="" src={props.src} />;
};

export default Images;
