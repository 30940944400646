export const NavbarData = [
  {
    title: "Home",
    path: "/",
    cName: "nav-text",
  },
  {
    title: "Arbeitszimmer",
    path: "/arbeitszimmer",
    cName: "nav-text",
  },
  {
    title: "Boot",
    path: "/boot",
    cName: "nav-text",
  },
  {
    title: "Bad",
    path: "/bad",
    cName: "nav-text",
  },
  {
    title: "Küche",
    path: "/kueche",
    cName: "nav-text",
  },
  {
    title: "Regal",
    path: "/regal",
    cName: "nav-text",
  },
  {
    title: "Wohnzimmer",
    path: "/wohnzimmer",
    cName: "nav-text",
  },
  {
    title: "Sonstiges",
    path: "/sonstiges",
    cName: "nav-text",
  },
  {
    title: "Schlafzimmer",
    path: "/schlafzimmer",
    cName: "nav-text",
  },
  {
    title: "Türen",
    path: "/tueren",
    cName: "nav-text",
  },
  {
    title: "Kolberg",
    path: "/kolberg",
    cName: "nav-text",
  },
];
