import React from "react";
import ImageContainer from "../Images";
import img1 from "../../images/schlafzimmer_01.jpg";
import img2 from "../../images/schlafzimmer_02.jpg";
import img3 from "../../images/schlafzimmer_03.jpg";
import img4 from "../../images/schlafzimmer_04.jpg";
import img5 from "../../images/schlafzimmer_05.jpg";
import img6 from "../../images/schlafzimmer_06.jpeg";
import img7 from "../../images/schlafzimmer_07.jpeg";
import ImageWrapper from "../ImageWrapper";
import PageWrapper from "../PageWrapper";

const Schlafzimmer = (children) => {
  return (
    <PageWrapper>
      <ImageWrapper>
        <ImageContainer src={img1} />
        <ImageContainer src={img2} />
        <ImageContainer src={img3} />
        <ImageContainer src={img4} />
        <ImageContainer src={img5} />
        <ImageContainer src={img6} />
        <ImageContainer src={img7} />
      </ImageWrapper>
    </PageWrapper>
  );
};

export default Schlafzimmer;
