import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  width: 65%;

  @media (max-width: 1024px) {
    width: 85%;
  }
`;

const ImageWrapper = (props) => {
  return <Wrapper>{props.children}</Wrapper>;
};

export default ImageWrapper;
