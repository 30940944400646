import React from "react";
import ImageContainer from "../Images";
import img1 from "../../images/sonstiges_01.jpg";
import img2 from "../../images/sonstiges_02.jpg";
import img3 from "../../images/sonstiges_03.jpeg";
import img4 from "../../images/sonstiges_04.jpeg";
import img5 from "../../images/sonstiges_05.jpeg";
import img6 from "../../images/sonstiges_06.jpeg";
import img7 from "../../images/sonstiges_07.jpeg";
import img8 from "../../images/sonstiges_08.png";
import img9 from "../../images/sonstiges_09.jpg";
import img10 from "../../images/sonstiges_10.jpg";
import img11 from "../../images/sonstiges_11.jpg";
import img12 from "../../images/sonstiges_12.jpg";
import img13 from "../../images/sonstiges_13.jpg";
import img14 from "../../images/sonstiges_14.jpg";
import ImageWrapper from "../ImageWrapper";
import PageWrapper from "../PageWrapper";

const Sonstiges = (children) => {
  return (
    <PageWrapper>
      <ImageWrapper>
        <ImageContainer src={img1} />
        <ImageContainer src={img2} />
        <ImageContainer src={img3} />
        <ImageContainer src={img4} />
        <ImageContainer src={img5} />
        <ImageContainer src={img6} />
        <ImageContainer src={img7} />
        <ImageContainer src={img8} />
        <iframe 
      title="vimeo-player" 
      src="https://player.vimeo.com/video/234854215?h=c7098cddf4" 
      width="640" 
      height="340" 
      frameborder="0" 
      allowfullscreen>
      </iframe>
        <ImageContainer src={img9} />
        <ImageContainer src={img10} />
        <ImageContainer src={img11} />
        <ImageContainer src={img12} />
        <ImageContainer src={img13} />
        <ImageContainer src={img14} />
      </ImageWrapper>
    </PageWrapper>
  );
};

export default Sonstiges;
