import React from "react";
import ImageContainer from "../Images";
import img1 from "../../images/boot_01.jpg";
import img2 from "../../images/boot_02.jpg";
import img3 from "../../images/boot_03.jpg";
import img4 from "../../images/boot_04.jpg";
import img5 from "../../images/boot_05.jpg";
import img6 from "../../images/boot_06.jpg";
import img7 from "../../images/boot_07.jpeg";
import img8 from "../../images/boot_08.jpeg";
import ImageWrapper from "../ImageWrapper";
import PageWrapper from "../PageWrapper";
import styled from "styled-components";

const Player = styled.div`
width: 100%;
padding-top: 2rem;

@media (max-width: 1024px) {
  width: 85%;
}
`;

const Boot = (children) => {
  return (
    <PageWrapper>
      <ImageWrapper>
        <Player>
        <iframe 
      title="vimeo-player" 
      src="https://player.vimeo.com/video/185239532?h=2cb1154ae5" 
      width="640" 
      height="340" 
      frameborder="0" 
      allowfullscreen>
      </iframe>
        </Player>
      
        <ImageContainer src={img1} />
        <ImageContainer src={img2} />
        {/* <ImageContainer src={img3} /> */}
        <ImageContainer src={img4} />
        <ImageContainer src={img5} />
        <ImageContainer src={img6} />
        <ImageContainer src={img7} />
        <ImageContainer src={img8} />
      </ImageWrapper>
    </PageWrapper>
  );
};

export default Boot;
